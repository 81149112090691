<template>
  <div class="home">
    <img alt="Vue logo" src="../assets/logo.png">
    <p>くじ １０億当たる！</p>
  </div>
</template>

<script>
// @ is an alias to /src
//import HelloWorld from '@/components/HelloWorld.vue'

export default {
  //name: 'HomeView',
  //components: {
  //  HelloWorld
  //}
}
</script>
