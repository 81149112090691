import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'

var lawViewName = "LawView.vue";
var plocyViewName = "PolicyView.vue";

var domainName = (new URL(window.location.href)).hostname;

let modules = require.context('../views/', false, /.*_*\.vue/,'lazy')
modules.keys().forEach(fileName => {
    fileName = fileName.replace("./", '');
    var fileName2 = fileName.toUpperCase().replace("_"+ domainName.toUpperCase()+".VUE",".VUE");

    switch (fileName2) {
      case lawViewName.toUpperCase():
        lawViewName = fileName;
        break;
      case plocyViewName.toUpperCase():
        plocyViewName = fileName;
        break;
      default:
        break;
    }
})

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  },
  {
    path: '/policy',
    name: 'policy',
    component: () => import(/* webpackChunkName: "policy" */ '../views/' + plocyViewName)
  },
  {
    path: '/law',
    name: 'law',
    component: () => import(/* webpackChunkName: "policy" */ '../views/' + lawViewName)
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
