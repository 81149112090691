<template>
    <Header />
    <router-view />
    <Footer />
</template>

<script>

import Header from './components/MainHeader.vue'
import Footer from './components/MainFooter.vue'
export default {
  components: {
    Header,
    Footer
  },
  data () {
    var domainName = (new URL(window.location.href)).hostname;
    return {
      domainName: domainName
    }
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}
</style>
