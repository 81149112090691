<template>
  <header>
    <img alt="WEBくじ" width="200" height="50" :src="imgSrc"  @error="foo"/>
    <br>
    <!--
    <img alt="WEBくじ2" width="200" height="50" :src="require(`../../public/img/${domain}/top.png`)"/>
    -->
    <nav>
        <router-link to="/">Home</router-link> |
        <router-link to="/about">About</router-link>
    </nav>
  </header>
</template>

<script>
const GetImg = function (filename) {
    try {
      return require("../../public/img/" + filename);
    } catch(e) {
      alert(e.message);
      console.error( "エラー：", e.message );
    }
}

export default {
  data () {
    var curDomain = (new URL(window.location.href)).hostname;
    return {
      title: "ABC株式会社",
      domain: curDomain,
      //imgSrc: require("../../public/img/" + curDomain + "/top.png"),
      //imgSrc: GetImg("../../public/img/" + curDomain + "/top.png")
      imgSrc: GetImg(curDomain + "/top.png"),
      under_maintenance: false,
      logged_in_view: false,
     // pending_order: {
      order: {
        present: false,
        lottery: {
          box_type: 1,
          identification_name: "a"
        }
      }
    }

  },
  methods: {
    foo: function (e) {
      alert(e);
    }
  }
}
</script>

<style scoped>
header {
  padding: 1em 0;
  background: #778899;
}
h1 {
  margin: 0;
  color: #fff;
}
</style>
