<template>
  <footer>
    <div>
      <p>{{msg}}</p>
      <nav>
         <router-link to="/policy">プライバシーポリシー</router-link> |
         <router-link to="/law">特定商取引法に基づく表記</router-link>
      </nav>

    </div>
  </footer>
</template>

<script>
export default {
  data () {
    return {
      msg: "CopyRight 2024"
    }
  }
}
</script>

<style scoped>
footer {
  background: #dcdcdc;
  padding: 0.5em 0;
}
</style>

